import jQuery from 'jquery';
import ResetPassword from './reset_password.js';

jQuery(($) => {
  let resetPass = new ResetPassword;

  // Change the color of the rules when they become valid.
  $('.password-validation-rules').each(resetPass.setupRuleSet);

  // Display the strength of a password
  $('.password-strength-meter').each(resetPass.setupStrengthMeter);

});