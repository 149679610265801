import zxcvbn from 'zxcvbn';
import $ from 'jquery';

export default class ResetPassword {
  setupStrengthMeter(index, element) {
    let meter = $(element);
    let inputId = meter.attr('data-input-id');
    let input = $('#' + inputId);

    if (input) {
      input.on('keyup change blur focus', () => {
        let pass = input.val();

        try {
          if ($.type(pass) !== 'string' || pass.length === 0) {
            meter.removeClass('visible');
          } else {
            meter.addClass('visible');

            let result = zxcvbn(pass);
            let scoreLevel = parseInt(result.score);

            meter.removeClass('score-level-0');
            meter.removeClass('score-level-1');
            meter.removeClass('score-level-2');
            meter.removeClass('score-level-3');
            meter.removeClass('score-level-4');
            meter.addClass('score-level-' + scoreLevel);
            meter.text('Password Strength: ' + (scoreLevel * 25));
          }
        } catch (err) {
          console.error(err);
        }
      });
    }
  }

  setupRuleSet(index, ruleSetEl) {
    let ruleList = $(ruleSetEl);
    let inputId = ruleList.attr('data-input-id');
    let input = $('#' + inputId);
    let rules = ruleList.find('li[data-rule]');
    let watchSelectors = ruleList.attr('data-watch-list').split(',');
    watchSelectors.push('#' + inputId);

    if (input) {
      $(watchSelectors.join(', ')).on('keyup change blur focus', () => {
        let pass = input.val();

        rules.each((index, el) => {
          let ruleEl = $(el);
          let rule = ruleEl.attr('data-rule').split(',');

          ruleEl.removeClass('valid');

          try {
            if (rule[0] === 'score') {
              let result = zxcvbn(pass);
              let scoreLevel = parseInt(result.score) * 25;

              switch (rule[1]) {
                case '=':
                  if (scoreLevel === parseInt(rule[2])) {
                    ruleEl.addClass('valid');
                  }
                  break;
                case '!=':
                  if (scoreLevel !== parseInt(rule[2])) {
                    ruleEl.addClass('valid');
                  }
                  break;
                case '<':
                  if (scoreLevel < parseInt(rule[2])) {
                    ruleEl.addClass('valid');
                  }
                  break;
                case '<=':
                  if (scoreLevel <= parseInt(rule[2])) {
                    ruleEl.addClass('valid');
                  }
                  break;
                case '>':
                  if (scoreLevel > parseInt(rule[2])) {
                    ruleEl.addClass('valid');
                  }
                  break;
                default:
                case '>=':
                  if (scoreLevel >= parseInt(rule[2])) {
                    ruleEl.addClass('valid');
                  }
                  break;
              }
            } else {
              if (rule[0] === 'length') {
                if ($.type(pass) === 'string' && pass.length >= parseInt(rule[2])) {
                  ruleEl.addClass('valid');
                }
              } else {
                if (rule[0] === 'confirmed') {
                  if ($.type(pass) === 'string' && pass === $(rule[1]).val()) {
                    ruleEl.addClass('valid');
                  }
                }
              }
            }
          } catch (err) {
            console.error(err);
          }
        });
      });
    }
  }
}